import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        const data = {
          data: {
            chatsContacts: [
              {
                id: 1,
                fullName: 'Felecia Rower',
                role: 'Frontend Developer',
                about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
                avatar: '/img/1.9cba4a79.png',
                status: 'offline',
                chat: {
                  id: 2,
                  unseenMsgs: 1,
                  lastMessage: {
                    message: 'I will purchase it for sure. 👍',
                    time: '2022-04-26T18:41:18.386Z',
                    senderId: 1,
                  },
                },
              },
              {
                id: 2,
                fullName: 'Adalberto Granzin',
                role: 'UI/UX Designer',
                about: 'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
                avatar: '/img/2.748e7504.png',
                status: 'busy',
                chat: {
                  id: 1,
                  unseenMsgs: 0,
                  lastMessage: {
                    message: 'If it takes long you can mail me at my mail address.',
                    time: '2022-04-25T18:41:18.386Z',
                    senderId: 11,
                  },
                },
              },
            ],
            contacts: [
              {
                id: 1,
                fullName: 'Felecia Rower',
                role: 'Frontend Developer',
                about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
                avatar: '/img/1.9cba4a79.png',
                status: 'offline',
                chat: {
                  id: 2,
                  unseenMsgs: 1,
                  lastMessage: {
                    message: 'I will purchase it for sure. 👍',
                    time: '2022-04-26T18:41:18.386Z',
                    senderId: 1,
                  },
                },
              },
              {
                id: 2,
                fullName: 'Adalberto Granzin',
                role: 'UI/UX Designer',
                about: 'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
                avatar: '/img/2.748e7504.png',
                status: 'busy',
                chat: {
                  id: 1,
                  unseenMsgs: 0,
                  lastMessage: {
                    message: 'If it takes long you can mail me at my mail address.',
                    time: '2022-04-25T18:41:18.386Z',
                    senderId: 11,
                  },
                },
              },
              {
                id: 3,
                fullName: 'Joaquina Weisenborn',
                role: 'Town planner',
                about: 'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
                avatar: '/img/3.5df49333.png',
                status: 'busy',
              },
              {
                id: 4,
                fullName: 'Verla Morgano',
                role: 'Data scientist',
                about: 'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
                avatar: '/img/4.7e5368dc.png',
                status: 'online',
              },
              {
                id: 5,
                fullName: 'Margot Henschke',
                role: 'Dietitian',
                about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
                avatar: '/img/5.f13458cc.png',
                status: 'busy',
              },
              {
                id: 6,
                fullName: 'Sal Piggee',
                role: 'Marketing executive',
                about: 'Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
                avatar: '/img/6.5e6ef87d.png',
                status: 'online',
              },
              {
                id: 7,
                fullName: 'Miguel Guelff',
                role: 'Special educational needs teacher',
                about: 'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
                avatar: '/img/7.8446f5dc.png',
                status: 'online',
              },
              {
                id: 8,
                fullName: 'Mauro Elenbaas',
                role: 'Advertising copywriter',
                about: 'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
                avatar: '/img/12.03bf9466.png',
                status: 'away',
              },
              {
                id: 9,
                fullName: 'Bridgett Omohundro',
                role: 'Designer, television/film set',
                about: 'Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.',
                avatar: '/img/9.3fd39137.png',
                status: 'offline',
              },
              {
                id: 10,
                fullName: 'Zenia Jacobs',
                role: 'Building surveyor',
                about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
                avatar: '/img/10.d618a41d.png',
                status: 'away',
              },
            ],
            profileUser: {
              id: 11,
              avatar: '/img/8.4ac55be3.png',
              fullName: 'John Doe',
              status: 'online',
            },
          },
        }
        resolve(data)
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/chat/chats/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
